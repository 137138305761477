<template>
  <transition v-if="show" name="slide">
    <div class="bg-gray font-row-item">
      <b-overlay :show="busy" spinner-variant="danger">
        <b-row class="py-3">
        <b-col align-self="center" cols="3">
          <b-button
            variant="outline-secondary"
            class="ml-5 w-75 font-row-item"
            v-show="application.status != 'rejected/verifier'"
            @click="getApplicant"
          >
            <b-spinner
              small
              v-show="applicanting"
              class="font-row-item"
            ></b-spinner>
            Depositor
          </b-button>
        </b-col>
        <b-col cols="6" align-self="center">
          <b-list-group>
            <b-list-group-item
              class="d-flex align-items-center"
              v-for="publishing in application.publishings"
              :key="publishing.id"
            >
              <div class="col-9">
                {{ publishing.publication_title }}
              </div>
              <div class="col-3">
                <b-button-group>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    class="mr-1"
                    @click="getPublishing(publishing)"
                  >
                    <b-icon-eye
                      class="font-row-item"
                    ></b-icon-eye>
                  </b-button>
                  <b-button variant="outline-danger" size="sm" :disabled="application.status!='unverified'||!$can('create_application')">
                    <b-icon-trash class="font-row-item"></b-icon-trash>
                  </b-button>
                </b-button-group>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="3" align-self="center">
          <div v-show="$can('verify_application')">
            <b-button
              variant="outline-danger"
              class="w-75 my-1 font-row-item"
              v-show="application.status == 'unverified'"
              @click="onVerify"
              >
              <b-spinner small v-show="verifying"></b-spinner>
              Verify
            </b-button
            >
            <b-button
              variant="danger"
              class="w-75 font-row-item mb-1"
              @click="onRefute"
              v-show="application.status == 'unverified'"
              >Reject</b-button
            >
          </div>
          <div v-show="$can('approve_application')">
            <approve-reject @approved="onApprove" @rejected="onReject">
              <template v-slot:approveBtn="props">
                <b-button
                  variant="outline-danger"
                  class="w-75 my-1 font-row-item"
                  v-show="application.status == 'verified'"
                  @click="props.approve"
                  >
                  <b-spinner small v-show="approving"></b-spinner>
                  Approve</b-button
                >
              </template>
              <template v-slot:rejectBtn="props">
                <b-button
                  @click="props.reject"
                  variant="danger"
                  class="w-75 font-row-item mb-1"
                  v-show="application.status == 'verified'"
                  >
                  <b-spinner small v-show="rejecting"></b-spinner>
                  Reject</b-button
                >
              </template>
            </approve-reject>
          </div>
          <div>
          <b-button v-show="application.status=='rejected/approver'" id="approver-comments" 
          @click="getApproverComments" size="sm" variant="danger" class="w-75">
            <b-icon-newspaper></b-icon-newspaper>
            Comments
          </b-button>
          <b-button v-show="application.status=='rejected/verifier'" id="verifier-comments" 
          @click="loadComments" size="sm" variant="danger" class="w-75">
            <b-icon-newspaper></b-icon-newspaper>
            Comments
          </b-button>
          </div>
        </b-col>
      </b-row>
      </b-overlay>
      <applicant :applicant="applicant"></applicant>
      <publishing :publishing="publication"></publishing>
    <comments-vue :busy="approver_comments.busy" :comments="approver_comments.body" target="approver-comments"></comments-vue>
    <comments-vue :busy="verifier_comments.loading" :comments="verifier_comments.body" target="verifier-comments"></comments-vue>
    </div>
  </transition>
</template>

<script>
import Applicant from "../applications/Applicant.vue";
import { APPL_STATUS } from "../applications";
import { createApi } from "../applications/api";
import Publishing from "./Publishing.vue";
import VerifyMixin from "../shared/VerifyMixin";
import { APP_TYPES} from "../shared";
import ApproveReject from "../../components/ApproveReject.vue";
import CommentsVue from "../../components/Comments.vue";
import ApproverMixin from "../shared/ApproverMixin";
const api = { deposits: createApi("deposits") };
export default {
  mixins: [VerifyMixin,ApproverMixin(api.deposits)],
  components: { Applicant, Publishing, ApproveReject,CommentsVue },
  props: ["application"],
  data() {
    return {
      show: false,
      verifying: false,
      busy:false,
      approving:false,
      rejecting:false,
      applicant: {
        first_name: "",
        last_name: "",
        email: "",
        parish: "",
        county: "",
        district: "",
        subcounty: "",
        postal_code: "",
        gender: "",
      },
      applicanting: false,
      publication: {
        id: "",
        tentative_title: "",
        cover_page_title: "",
        number_of_titles: "",
        author: { name: "" },
        nature: "single",
        publisher: { name: "" },
      }
    };
  },
  created() {
    this.$nextTick(() => {
      this.show = true;
    });
    this.type = APP_TYPES.DEPOSIT;
    this.api = api.deposits;
    this.prompt = {
      verification: {
        title: "Verification Notice",
        body: "Comments on deposit",
      },
      rejection: {
        title: "Rejection Notice",
        body: "Comments on deposit",
      },
    };
  },
  methods: {
    onApprove(){
      this.approving = true;
      api.deposits.approver.approve(this.application.id).then(()=>{
        this.approving = false;
        this.application["status"] = APPL_STATUS.APPROVED;
        this.$store.dispatch('deposit/update',this.application);
      });
    },
    /**
     * 
     * @param {string} comments 
     */
    onReject(comments){
      this.rejecting = true;
      api.deposits.approver.reject(this.application.id,comments).then(()=>{
        this.rejecting = false;
        this.application["status"]=APPL_STATUS.REJECTED_APPROVER;
        this.$store.dispatch('deposit/update',this.application);
      });
    },
    getApplicant() {
      this.applicanting = true;
      api.deposits.getApplicant(this.application.id).then((applicant) => {
        this.applicanting = false;
        this.applicant = applicant;
        this.$bvModal.show("the-applicant");
      });
    },
    /**
     *
     * @param {import("../applications/api").Publishing} item
     */
    getPublishing(item) {
      this.busy = true;
      api.deposits
        .getPublication(this.application.id, item.id)
        .then((publication) => {
          this.busy = false;
          this.publication = publication;
          this.$bvModal.show("the-publishing");
        });
    },

  },
};
</script>

<style>
.font-row-item {
  font-size: 0.85em;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>