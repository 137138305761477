<template>
  <b-popover :target="target" triggers="click blur">
    <template #title>Comments</template>
    <b-skeleton width="100%" v-show="busy"></b-skeleton>
    <p v-show="!busy">
        {{comments}}
    </p>
  </b-popover>
</template>

<script>
export default {
    props:["comments","busy","target"]
}
</script>

<style>

</style>