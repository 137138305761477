import Vue from "vue";
import { STORES } from ".";
import {PAY_STATUS,APPL_STATUS} from "../applications";
export default Vue.mixin({
  data(){
    return {
        isBusy:false
    }
  },
  filters: {
    paint_payment(status) {
      return status == PAY_STATUS.PAID ? "success" : "danger";
    },
    fmt_pay(status) {
      return status == PAY_STATUS.PENDING ? "unpaid" : PAY_STATUS.PAID;
    },
  },
  computed:{
    applications() {
        const appls = this.$store.getters[STORES[this.type]+"/getApplications"];
        let status = this.$route.query["status"];
        return status == undefined ?appls:appls.filter((el) => el.status == status);
      },
  },
  methods:{
    getApplications() {
        this.isBusy = true;
        this.$store.dispatch(STORES[this.type]+"/loadApplications").then(() => {
          this.isBusy = false;
        });
    },
    canDelete(status){
        return status == APPL_STATUS.UNVERIFIED && this.$can('delete_application');
    },
    refresh(){
        this.getApplications();
    },
  }
});
