import Vue from "vue";

export default (api)=>{
    return Vue.mixin({
        data(){
            return {
                approver_comments:{
                    busy:false,
                    body:""
                }
            }
        },
        methods:{
            getApproverComments(){
                this.approver_comments.busy=true;
                api.approver.comments(this.application.id).then(reason=>{
                  this.approver_comments.busy=false;
                  this.approver_comments.body=reason;
                })
              }
        }
    })
};