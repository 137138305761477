<template>
  <div style="min-height:75vh;">
    <div class="d-flex my-2">
      <b-form inline class="search-app">
        <b-form-input
          type="search"
          class="w-75"
          placeholder="Search deposits by publication"
        ></b-form-input>
      </b-form>
      <div>
        <b-button variant="outline-danger" class="ml-auto" @click="refresh" size="sm">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
        </b-button>
      </div>
    </div>
    <b-table
      :fields="fields"
      :items="applications"
      thead-class="d-none"
      hover
      responsive
      :busy="isBusy"
      table-variant="light"
      @row-clicked="onRowClick"
      class="applications-table"
      size="sm"
      show-empty
    >
    <template #empty>
      <h5 class="text-center text-muted">No deposits yet</h5>
    </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(publishing)="data">
        <div class="application-title">
          {{ data.item.publishings | fmt_title }}
        </div>
      </template>
      <template #cell(show_details)="row">
        <b-button variant="link" size="sm" @click="onRowClick(row)">
          <b-icon-eye v-show="!row.detailsShowing" class="text-danger"></b-icon-eye>
          <b-icon-eye-slash v-show="row.detailsShowing" class="text-danger"></b-icon-eye-slash>
        </b-button>
      </template>
      <template #cell(actions)="data">
        <b-button variant="link" @click="dropApp(data.item)" size="sm" :disabled="!canDelete(data.item.status)">
          <b-icon-trash :class="{'text-muted':!canDelete(data.item.status)}" class="text-danger" v-show="!data.item.deleting"></b-icon-trash>
          <b-spinner class="text-danger" v-show="data.item.deleting" small></b-spinner>
        </b-button>
      </template>

      <template #row-details="row">
        <deposit :application="row.item"></deposit>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import BaseMixin from "./mixins";
import ListApplicationsMixin from "../shared/ListApplicationsMixin";
import { APP_TYPES, DIALOG_STATUS, STORES } from '../shared';
import Deposit from './Deposit.vue';
export default {
  components: { Deposit },
  mixins: { ListApplicationsMixin,BaseMixin},
  filters: {
    fmt_title(publishings) {
      if (publishings.length == 1) return publishings[0]["publication_title"];
      if (publishings.length > 1)
        return publishings[0]["publication_title"] + ` + ${publishings.length-1} more`;
    },
  },
  computed:{
    fields(){
      const items =  [{ key: "drn", class: "font-weight-bold" },"publishing",
        { key: "created_at",formatter: (cdate) => {return moment(cdate).format("MMM DD");},},
        'show_details',"actions"];
      const status = this.$route.query["status"];
      if(status==undefined){
        items.splice(2,0,{key:"status",
        class:['status-text'],
        formatter:(cstatus)=>{return cstatus.toUpperCase();}});
      }
      return items;
    }
  },
  data() {
    return {
      type:APP_TYPES.DEPOSIT
    };
  },
  methods: {
    onRowClick(item) {
      item.toggleDetails();
    },
    /**
     * 
     * @param {{id:number,deleting:false}} item 
     */
    dropApp(item) {
        item.deleting = true;
        this.dropApplication(item.id).then((status) => {
          if (status) {
            this.$store.dispatch(STORES[this.type]+"/dropApplication", item.id);
          }
        }).catch((err)=>{
          if(err==DIALOG_STATUS.CANCELED){
            item.deleting = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.application-title {
  width: 35ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-app {
  width: 66%;
}
.drop-app {
  font-size: 0.5em;
}
</style>
<style>

.applications-table>table>tbody>tr{
  border-bottom-width: 0.5%;
  border-bottom-color:rgba(0, 0, 0, 0.05);
  border-bottom-style: solid;
  vertical-align: middle !important;
}
.applications-table>table>tbody>tr>td{
  vertical-align: middle;
}
.status-text{
  font-size:small;
  font-weight:bold;
  text-align: left;
  vertical-align: middle !important;
}
</style>
