<template>
  <b-modal
    id="the-publishing"
    hide-footer
    content-class="bg-gray"
    title="Publication Details"
    size="huger"
  >
    <div>
      <b-row>
        <display-field
          class="col-9"
          label="Publication Title"
          :field="publishing.publication_title"
        >
        </display-field>
        <display-field
          class="col-3"
          label="ISSN"
          :field="publishing.issn"
          v-if="isMagazine"
        ></display-field>
        <display-field
          class="col-3"
          label="ISBN"
          :field="publishing.isbn"
          v-else
        >
        </display-field>
      </b-row>
      <b-row class="my-1">
        <display-field
          class="col-9"
          label="Original Title"
          :field="publishing.original_title"
        ></display-field>
        <display-field
          class="col-3"
          label="Issue Date"
          :field="publishing.issue_date | fmt_date"
          v-if="isMagazine"
        >
        </display-field>
        <display-field
          class="col-3"
          label="Pub. Date"
          :field="publishing.publication_date | fmt_date"
          v-else
        >
        </display-field>
      </b-row>
      <b-card no-body class="overflow-hidden book-preview">
        <b-row no-gutters>
          <b-col cols="4">
            <b-card-img
              :src="publishing.path"
              alt="Image"
              class="rounded-0"
            ></b-card-img>
          </b-col>
          <b-col cols="8" align-self="center">
            <b-card-body>
              <b-card-text>
                <display-field label="Publisher" :field="publishing.publisher | fmt_publisher" class="mb-1">
                </display-field>
                <display-field
                  label="Author"
                  :field="publishing.author | fmt_author"
                ></display-field>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import DisplayField from "../applications/publication/DisplayField.vue";
export default {
  props: ["publishing"],
  components: { DisplayField },
  computed: {
    isMagazine() {
      return this.publishing["issn"] ? true : false;
    },
  },
  filters: {
    fmt_author(arthur) {
      return arthur["name"] + " , " + arthur["email"];
    },
    fmt_publisher(publisher) {
      return publisher["name"] + ", " + publisher["physical_address"];
    },
    fmt_date(dt) {
      return moment(dt).format("MMM YYYY");
    },
  },
};
</script>

<style>
.modal .modal-huger {
  max-width: 37em;
  width: 37em;
}
.book-preview{
    max-width: 23.40em;
}
</style>